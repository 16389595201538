<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="800"
      @click:outside="reset"
    >
      <v-card>
        <v-card-title class="headline">
          {{ isEditing ? "Edit" : "Add a new" }} Event
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
          <v-form @submit.prevent="save" method="post" id="event-form">
            <div>
              <v-text-field
                label="Name *"
                v-model="fields.name"
                type="text"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('name')"
                :error-messages="errors['name']"
              ></v-text-field>

              <v-text-field
                v-if="isEditing"
                label="Slug *"
                v-model="fields.slug"
                type="text"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('slug')"
                :error-messages="errors['slug']"
              ></v-text-field>

              <v-text-field
                v-else
                label="Slug *"
                v-model="slug"
                type="text"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('slug')"
                :error-messages="errors['slug']"
              ></v-text-field>

              <v-text-field
                label="Start Date*"
                v-model="fields.start_date"
                type="date"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('start_date')"
                :error-messages="errors['start_date']"
              ></v-text-field>

              <v-text-field
                label="End Date*"
                v-model="fields.end_date"
                type="date"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('end_date')"
                :error-messages="errors['end_date']"
              ></v-text-field>

              <v-text-field
                label="Region"
                v-model="fields.region"
                type="text"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('region')"
                :error-messages="errors['region']"
              ></v-text-field>

              <v-textarea
                label="Short Description"
                v-model="fields.short_description"
                counter
                outlined
                :rules="[(v) => v.length <= 500 || 'Max 500 characters']"
                background-color="white"
                :error="errors.hasOwnProperty('short_description')"
                :error-messages="errors['short_description']"
              ></v-textarea>
              <tiptap
                v-model="fields.description"
                label="Event Description"
                class="mb-8"
              />
              <v-file-input
                label="Event Image*"
                v-model="fields.main_image"
                accept="image/png, image/jpeg"
                placeholder="Pick an image"
                prepend-icon="mdi-camera"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('main_image')"
                :error-messages="errors['main_image']"
              ></v-file-input>

              <v-switch
                label="Draft"
                v-model="fields.draft"
                inset
                :error="errors.hasOwnProperty('draft')"
                :error-messages="errors['draft']"
              ></v-switch>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <div v-if="errorMessage" class="red--text lighten-4">
            <v-icon small class="red--text mr-2">mdi-alert-circle</v-icon
            >{{ errorMessage }}
          </div>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="reset()">Close</v-btn>
          <v-btn
            color="accent"
            text
            type="submit"
            :loading="loading"
            form="event-form"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Tiptap from "@/views/components/Tiptap.vue";

export default {
  components: {
    Tiptap,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      event: {},
      fields: {
        event_type: null,
        name: null,
        slug: null,
        start_date: null,
        start_time: null,
        end_date: null,

        region: null,
        date_time_text: null,

        is_paid: false,
        short_description: "",
        description: null,
        has_allocation: true,
        allocation: null,

        main_image: null,
        time_blocks: [],
        removed: [],
        draft: true,
      },

      errors: {},
      errorMessage: null,
    };
  },

  methods: {
    open(event = null) {
      if (event !== null) {
        this.event = event;
        this.isEditing = true;
        this.fields.event_type = event.event.type;
        this.fields.name = event.name;
        this.fields.slug = event.slug;
        this.fields.start_date = event.start_date.substr(0, 10);
        this.fields.start_time = event.start_time;
        this.fields.end_date = event.end_date.substr(0, 10);
        this.fields.end_time = event.end_time;
        this.fields.location = event.location;
        this.fields.date_time_text = event.date_time_text;

        this.fields.is_paid = event.is_paid;
        this.fields.description = event.description;
        this.fields.draft = event.draft;

        this.fields.short_description = event.short_description || "";

        if (event.event.type === "christmas") {
          if (event.event.time_blocks.length > 0) {
            this.fields.time_blocks = event.event.time_blocks.map(
              (time_block) => {
                return {
                  id: time_block.id,
                  date: time_block.date,
                  start_time: time_block.start_time,
                  end_time: time_block.end_time,
                  allocation: time_block.allocation,
                };
              }
            );
          }
        }
      }

      this.dialog = true;
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.event = {};
      this.fields = {
        event_type: null,
        name: null,
        slug: null,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        location: null,
        date_time_text: null,
        is_featured: false,
        is_ticketed: false,
        is_paid: false,
        description: null,
        short_description: "",
        has_allocation: true,
        allocation: null,
        ticket_price_under_3: null,
        ticket_price_over_3: null,
        main_image: null,
        time_blocks: [],
        removed: [],
        draft: true,
      };
      this.errorMessage = null;
      this.$refs.formWrapper.scrollTop = 0;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.eventId = this.event.id;
      }

      this.$store
        .dispatch("eow/eventsStore/saveEvent", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;

          if (this.errors) {
            this.errorMessage =
              "Please ensure all fields are completed correctly";

            this.$refs.formWrapper.scrollTop = 0;
          }
        });
    },

    saveTimeBlock(fields, isEditing, key) {
      if (isEditing) {
        this.fields.time_blocks[key].date = fields.date;
        this.fields.time_blocks[key].start_time = fields.start_time;
        this.fields.time_blocks[key].end_time = fields.end_time;
        this.fields.time_blocks[key].allocation = fields.allocation;
      } else {
        this.fields.time_blocks.push(fields);
      }
    },

    deleteTimeBlock(key) {
      if (this.fields.time_blocks[key]["id"]) {
        this.fields.removed.push(this.fields.time_blocks[key]["id"]);
      }

      this.fields.time_blocks.splice(key, 1);
    },
  },

  computed: {
    slug: {
      get() {
        if (this.isEditing) {
          return this.fields.slug;
        }

        let slug = this.fields.name;

        if (slug === "" || slug === null) {
          return slug;
        }

        slug = slug.trim();
        slug = slug.replaceAll("&", "and");
        slug = slug.replaceAll(",", "");
        slug = slug.replaceAll(/[^a-zA-Z ]/g, "");
        slug = slug.replaceAll(" ", "-");
        slug = slug.toLowerCase();
        if (slug.substr(-1) === "-") {
          slug = slug.substr(0, slug.length - 1);
        }

        return slug;
      },
      set(value) {
        return value;
      },
    },
  },

  watch: {
    slug(value) {
      this.fields.slug = value;
    },
  },
};
</script>
